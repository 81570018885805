import React, { useEffect } from "react";
import { Modal, Form, Input, Alert, Divider, Select, notification } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { editSubservice, getSubservices } from "./api";
import { RootState } from "../../App";

import { clearSubserviceStatus, hideEditSubserviceModal, showEditSubserviceModal } from "./slice";
import { slug } from "../common/utils/slug";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";

export default function EditModal(props: {
  row: {
    name: string;
    notes: string;
    id: string;
    website: string;
    uniqueID: string;
    serviceID: number;
    created_at: string;
    updated_at: string;
  };
}) {
  const { row } = props;
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  // const [service, setService] = React.useState<String | null>(null);

  const serviceOptions = useSelector((state: RootState) => state.services.all);
  const retailerId = useSelector(
    (state: RootState) => state.subservices.currentSubservice?.id
  );

  const visible = useSelector(
    (state: RootState) => state.subservices.showEditModal === row.id
  );



  const loading = useSelector(
    (state: RootState) => state.subservices.editSubserviceLoading
  );

  const error = useSelector(
    (state: RootState) => state.subservices.editSubserviceError
  );
  const {editError,editSuccess} = useSelector((state : RootState) => state.subservices);

  //Get login user detail
  const loggedInUser = useSelector((state: RootState) => state.auth.user);

  useEffect(()=>{
    if(editSuccess){
      notification.success({
        message: 'Success Message',
        description: `${row.name} subservice updated successfully.`
      })
      dispatch(clearSubserviceStatus());
    }
    if(editError){
      notification.error({
        message: 'Error Message',
        description: `Unable to update ${row.name} subservice.`
      })
      dispatch(clearSubserviceStatus());
    }
  },[editError,editSuccess])

  //Action on submit button
  const submitForm = async (values: any) => {
    console.log("before");
    await dispatch(editSubservice({ ...row, ...values }));
    console.log("after");

    await dispatch(getSubservices({ retailerId: retailerId }));
    dispatch(showEditSubserviceModal(null));
  };

  const handleModalOk = () => {
    //validates values then sends them to submitForm()
    form
      .validateFields()
      .then((values) => {
        submitForm(values);
      })
      .catch((e) => { });
  };
  // handle cancel button click
  const handleCancel = () => {
    form.resetFields();
    dispatch(hideEditSubserviceModal());
  };
  // handle Form change
  const handleFormChange = (changedValues: any, allValues: any) => {
    if (changedValues.name && slug(changedValues.name) !== allValues.slug) {
      form.setFieldsValue({
        name: allValues.name,
        slug: slug(allValues.name),
      });
    }
  };
  // layout object
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };



  return (
    <Modal
      maskClosable={false}
      title="Edit Subservice"
      visible={visible}
      okText="Update"
      onOk={handleModalOk}
      onCancel={handleCancel}
      okButtonProps={loading ? { loading } : undefined}
      destroyOnClose={true}
    >

      {
        row.updated_at == null || row.updated_at === ''
        ? (<span style={{ float: 'right' }}><b>Created: </b> {moment(row.created_at).format("MM/DD/YYYY")} {moment(row.created_at).format("hh:mma")} by {loggedInUser?.firstName} {loggedInUser?.lastName}</span>)
        : (
            <>
              <span style={{ float: 'right' }}><b>Created: </b> {moment(row.created_at).format("MM/DD/YYYY")} {moment(row.created_at).format("hh:mma")} by {loggedInUser?.firstName} {loggedInUser?.lastName}</span>
              <br/>
              <span style={{ float: 'right' }}><b>Last Edited: </b> {moment(row.updated_at).format("MM/DD/YYYY")} {moment(row.updated_at).format("hh:mma")} by {loggedInUser?.firstName} {loggedInUser?.lastName}</span>
            </>
          ) 
      }
      <br/><br/>

      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}
      <Form
        {...layout}
        form={form}
        name={`editSubservice#${row.id}`}
        initialValues={{
          name: row.name,
          serviceID: row.serviceID,
          notes: row.notes,
          uniqueID: row.uniqueID,
          website: row.website,
        }}
        onValuesChange={handleFormChange}
      >
        <Form.Item
          label="Subservice Name"
          name="name"
          rules={[{ required: true, message: "Subservice name is required!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Service name"
          name="serviceID"
          rules={[{ required: true, message: "Subservice name is required!" }]}
        >
          <Select
            value={row.serviceID}
            style={{ width: 315 }}
            showSearch={true}
            filterOption={(inputValue, option) => {
              return (
                option?.children
                  .toLowerCase()
                  .indexOf(inputValue.toLowerCase()) >= 0 ?? false
              );
            }}
          >
            {serviceOptions.map((option) => (
              <Select.Option key={option.id} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Website"
          name="website"
          rules={[{ type: "url", message: "Please enter a valid website url." }]}
        >
          <Input onKeyDown={(event) => {
            if (event.keyCode == 32)
              event.preventDefault();
          }} />
        </Form.Item>
        <Form.Item
          label="Unique ID:"
          name="uniqueID"
          rules={[{ required: true, message: "Unique ID is required!" }]}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item label="Notes (Optional)" name="notes">
          <TextArea rows={6} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
