import React, { useEffect } from "react";
import { Modal, Form, Input, Alert, Divider, Select,notification } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../App";

import { clearServiceStatus, hideEditServiceModal } from "./slice";
import { slug } from "../common/utils/slug";
import TextArea from "antd/lib/input/TextArea";
import { editService, getServices } from "./api";
import moment from "moment";

export default function EditModal(props: {
  row: {
    name: string;
    uniqueID: string;
    id: string;
    notes: string;
    created_at: string;
    updated_at: string;
    retailerId : string;
  };
}) {

  const { row } = props;
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const visible = useSelector(
    (state: RootState) => state.services.showEditModal === row.id
  );

  const loading = useSelector(
    (state: RootState) => state.services.editServiceLoading
  );

  const error = useSelector(
    (state: RootState) => state.services.editServiceError
  );
  const retailerId = useSelector(
    (state: RootState) => state.retailers.currentRetailer?.id
  );
  const {editError,editSuccess} = useSelector((state : RootState) => state.services);
  // const retailers = useSelector((state: RootState) => state.retailers.all);

  //Get login user detail
  const loggedInUser = useSelector((state: RootState) => state.auth.user);

  useEffect(()=>{
    if(editSuccess){
      notification.success({
        message: 'Success Message',
        description: `${row.name} service updated successfully.`
      })
      dispatch(clearServiceStatus())
    }
    if(editError){
      notification.error({
        message: 'Error Message',
        description: `Unable to update ${row.name} service.`
      })
      dispatch(clearServiceStatus())
    }
  },[editError,editSuccess])

  // submit form
  const submitForm = (values: any) => {
    dispatch(editService({ ...row, ...values }));
    dispatch(getServices({ retailerId: retailerId }))
  };

  // handle modal okay
  const handleModalOk = () => {
    //validates values then sends them to submitForm()
    form
      .validateFields()
      .then((values) => {
        submitForm(values);
      })
      .catch((e) => { });
  };
  // handle cancel
  const handleCancel = () => {
    form.resetFields();
    dispatch(hideEditServiceModal());
  };
  // handle form change
  const handleFormChange = (changedValues: any, allValues: any) => {
    if (changedValues.name && slug(changedValues.name) !== allValues.slug) {
      form.setFieldsValue({
        name: allValues.name,
        slug: slug(allValues.name),
      });
    }
  };
  // layout object
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Modal
      maskClosable={false}
      title="Edit Service"
      visible={visible}
      okText="Update"
      onOk={handleModalOk}
      onCancel={handleCancel}
      okButtonProps={loading ? { loading } : undefined}
      destroyOnClose={true}
    >
      
      {
        row.updated_at == null || row.updated_at === ''
        ? (<span style={{ float: 'right' }}><b>Created: </b> {moment(row.created_at).format("MM/DD/YYYY")} {moment(row.created_at).format("hh:mma")} by {loggedInUser?.firstName} {loggedInUser?.lastName}</span>)
        : (
            <>
              <span style={{ float: 'right' }}><b>Created: </b> {moment(row.created_at).format("MM/DD/YYYY")} {moment(row.created_at).format("hh:mma")} by {loggedInUser?.firstName} {loggedInUser?.lastName}</span>
              <br/>
              <span style={{ float: 'right' }}><b>Last Edited: </b> {moment(row.updated_at).format("MM/DD/YYYY")} {moment(row.updated_at).format("hh:mma")} by {loggedInUser?.firstName} {loggedInUser?.lastName}</span>
            </>
          ) 
      }
      <br/><br/>

      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}
      <Form
        {...layout}
        form={form}
        name={`editRetailer#${row.id}`}
        initialValues={{
          name: row.name,
          uniqueID: row.uniqueID,
          retailerId : row.retailerId,
          id: row.id,
          notes: row.notes,
          created_at: row.created_at,
          updated_at: row.updated_at,
        }}
        onValuesChange={handleFormChange}
      >
        {/* <Form.Item
          label="Select Retailer"
          name="retailerId"
          rules={[{ required: true, message: "Retailer name is required!" }]}
        >
          <Select defaultValue={row.retailerId} >
            {
              retailers && retailers.map((item, index) => (
                <Select.Option value={item.id} key={index}>{item.name} </Select.Option>
              ))
            }
          </Select>
        </Form.Item> */}


        <Form.Item
          label="Service name"
          name="name"
          rules={[{ required: true, message: "Service name is required!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Unique ID:"
          name="uniqueID"
          rules={[{ required: true, message: "Unique ID is required!" }]}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item label="Notes (Optional)" name="notes">
          <TextArea rows={6} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
