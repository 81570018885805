import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getProperties, addProperty, editProperty, deleteProperty, getPropertiesBySubServiceId } from "./api";
import { Properties } from "./models";

export type PropertyState = {
  all: Properties[];
  propertyBySubService: Properties[],
  currentProperty?: Properties;
  loading: boolean;
  error: string | null;
  showAddModal: boolean;
  addPropertyLoading: boolean;
  addPropertyError: string | null;
  showEditModal: string | null;
  editPropertyLoading: boolean;
  editPropertyError: string | null;
  showDeleteModal: string | null;
  showViewModal: string | null;
  viewPropertyError: string | null;
  deletePropertyLoading: boolean;
  deletePropertyError: string | null;
  addSuccess: boolean;
  addError: boolean;
  editSuccess: boolean;
  editError: boolean;
  deleteSuccess: boolean;
  deleteError: boolean;
};

const initialState: PropertyState = {
  all: [],
  propertyBySubService: [],
  currentProperty: undefined,
  loading: false,
  error: null,
  addPropertyError: null,
  showAddModal: false,
  showViewModal: null,
  addPropertyLoading: false,
  showEditModal: null,
  editPropertyLoading: false,
  editPropertyError: null,
  showDeleteModal: null,
  deletePropertyLoading: false,
  deletePropertyError: null,
  viewPropertyError: null,
  addSuccess: false,
  addError: false,
  editSuccess: false,
  editError: false,
  deleteSuccess: false,
  deleteError: false,
};

export const propertiesSlice = createSlice({
  name: "properties",
  initialState,
  reducers: {
    setCurrentProperty: (state, action: PayloadAction<Properties>) => {
      state.currentProperty = action.payload;
      state.editPropertyError = null;
    },
    showAddPropertyModal: (state) => {
      state.showAddModal = true;
    },
    hideAddPropertyModal: (state) => {
      state.showAddModal = false;
      state.addPropertyError = null;
    },
    showEditPropertyModal: (state, action) => {
      state.showEditModal = action.payload;
    },
    hideEditPropertyModal: (state) => {
      state.showEditModal = null;
      state.editPropertyError = null;
    },
    showViewPropertyModal: (state, action) => {
      state.showViewModal = action.payload;
    },
    hideViewPropertyModal: (state) => {
      state.showViewModal = null;
      state.viewPropertyError = null;
    },
    showDeletePropertyModal: (state, action) => {
      state.showDeleteModal = action.payload;
    },
    hideDeletePropertyModal: (state) => {
      state.showDeleteModal = null;
      state.deletePropertyError = null;
    },
    clearProperties: (state) => {
      state.propertyBySubService = [];
    },
    clearPropertiesStatus: (state) => {
      state.addSuccess = false;
      state.addError = false;
      state.editSuccess = false;
      state.editError = false;
      state.deleteSuccess = false;
      state.deleteError = false;
    }
  },
  extraReducers: (builder) => {
    /* get retailers */
    builder.addCase(getProperties.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProperties.fulfilled, (state, action) => {
      let data: Properties[] = []
      action.payload.forEach((item) => {
        data.push({
          ...item,
          retailerName: item.retailerName == null ? item.nameRetailer : item.retailerName
        })
      })
      state.all = data;
      state.loading = false;
      state.editPropertyError = null;
      state.error = null;
    });
    builder.addCase(getProperties.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.error = action.payload;
      }
    });

    /* add retailer*/
    builder.addCase(addProperty.pending, (state) => {
      state.addPropertyLoading = true;
      state.addPropertyError = null;
      state.addSuccess = false;
      state.addError = false;
    });
    builder.addCase(addProperty.fulfilled, (state, action) => {
      state.all = [...state.all, action.payload];
      state.addPropertyLoading = false;
      state.addPropertyError = null;
      state.showAddModal = false;
      state.addSuccess = true;
      state.addError = false;
    });
    builder.addCase(addProperty.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.addPropertyError = action.payload;
        state.addPropertyLoading = false;
        state.addSuccess = false;
        state.addError = true;
      }
    });

    /* edit retailer*/
    builder.addCase(editProperty.pending, (state) => {
      state.editPropertyLoading = true;
      state.editPropertyError = null;
      state.editSuccess = false;
      state.editError = false;
    });
    builder.addCase(editProperty.fulfilled, (state, action) => {
      state.all = state.all.map((item) =>
        item?.id === action?.payload?.id ? action?.payload : item
      );
      state.editPropertyLoading = false;
      state.editPropertyError = null;
      state.showEditModal = null;
      state.editSuccess = true;
      state.editError = false;
    });
    builder.addCase(editProperty.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.editPropertyError = action.payload;
        state.editPropertyLoading = false;
        state.editSuccess = false;
        state.editError = false;
      }
    });

    /* delete retailer*/
    builder.addCase(deleteProperty.pending, (state) => {
      state.deletePropertyLoading = true;
      state.deletePropertyError = null;
      state.deleteSuccess = false;
      state.deleteError = false;
    });
    builder.addCase(deleteProperty.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.deletePropertyError = action.payload;
        state.deletePropertyLoading = false;
        state.deleteSuccess = false;
        state.deleteError = true;
      }
    });
    builder.addCase(deleteProperty.fulfilled, (state, action) => {
      state.all = state.all.filter((item) => item.id !== action.payload);
      state.deletePropertyLoading = false;
      state.showDeleteModal = null;
      state.deletePropertyError = null;
      state.deleteSuccess = true;
      state.deleteError = false;
    });

    /* get properties using subserviceID */
    builder.addCase(getPropertiesBySubServiceId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPropertiesBySubServiceId.fulfilled, (state, action) => {
      state.propertyBySubService = action.payload;
      console.log(action.payload,"properties payload");
      
      state.loading = false;
      state.editPropertyError = null;
      state.error = null;
    });
    builder.addCase(getPropertiesBySubServiceId.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.error = action.payload;
      }
    });
  },
});

export const {
  showAddPropertyModal,
  hideAddPropertyModal,
  showEditPropertyModal,
  hideEditPropertyModal,
  showDeletePropertyModal,
  hideDeletePropertyModal,
  showViewPropertyModal,
  hideViewPropertyModal,
  clearProperties,
  clearPropertiesStatus
} = propertiesSlice.actions;
