import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../App";
import styled from "styled-components";
import { getRetailers } from "../retailers/api";
import { Select } from "antd";
import { authSlice } from "../auth/slice";
import { Actions } from "../Reducers";
import { User } from "../auth/models";
import { EditCurrentUserModal } from "./EditCurrentUserModal";
import { UserSettings } from "./UserSettings";
import { ChangePasswordModal } from "./ChangePasswordModal";
import { SettingModal } from "./SettingModal";
import { FacebookProvider, LoginButton, useLoginStatus } from 'react-facebook';
const NavParent = styled.div`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100vw;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding-inline: 10vw;
  padding: 8px 0px;
  position: relative;
  z-index: 1000;
`;

export const Navbar = () => {
  const user: User = useSelector<RootState>((state) => state.auth.user) as User;
  const dispatch = useDispatch();
  const retailers = useSelector((state: RootState) => state.retailers.all);
  const retailerName = useSelector(
    (state: RootState) => state.auth.user?.retailer?.name
  );
  const retailerSelectable = useSelector(
    (state: RootState) => state.currentRetailer.retailerSelectable
  );

  const editCurrentUserModalVisible = useSelector(
    (state: RootState) => state.login.editCurrentUserModalVisible
  );
  const changePasswordModalVisible = useSelector(
    (state: RootState) => state.login.changePasswordModalVisible
  );

  const SettingModalVisible = useSelector(
    (state: RootState) => state.login.SettingModalVisible
  );


  

  const [selectedValue, setSelectedValue] = useState("_ALL_");
  useEffect(() => {
    dispatch(getRetailers({ retailerId: undefined }));
  }, [dispatch]);

  return (
    <NavParent>

      <div
        style={{
          width: 148,
          height: 32,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          overflow: "hidden",

          // cause I want the e from epic aligned with the icons from the sidebar
          paddingLeft: 9,
        }}
      >
        <img
          alt=""
          src={require("../../resources/logo.png")}
          style={{ maxWidth: 64 }}
        />
      </div>
      <div
        style={{
          flex: 1,
          paddingLeft: 32,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {retailerSelectable && (
          <Select
            style={{ minWidth: "240px" }}
            value={selectedValue}
            placeholder={"Select retailer"}
            bordered={false}
            onSelect={(a, option) => {
              setSelectedValue(option.value);
              dispatch(
                Actions.currentRetailer.setCurrentRetailer(
                  option.value === "_ALL_"
                    ? undefined
                    : retailers.find((r) => r.id === option.key)
                )
              );
              dispatch(getRetailers({ retailerId: option.key as string }));
            }}
          >
            <Select.Option value={"_ALL_"} key={"_ALL_"}>
              {"All retailers"}
            </Select.Option>
            {retailers.map((r) => (
              <Select.Option value={r.id} key={r.id}>
                {r.name}
              </Select.Option>
            ))}
          </Select>
        )}
        {!retailerSelectable && <h3>{`${retailerName}`}</h3>}
        <div>
          <EditCurrentUserModal
            visible={editCurrentUserModalVisible}
            onCancel={() => {
              dispatch(Actions.login.setCurrentUserModalVisible(false));
            }}
          />
          <ChangePasswordModal
            visible={changePasswordModalVisible}
            onCancel={() => {
              dispatch(Actions.login.setChangePasswordModalVisible(false));
            }}
          />
          <SettingModal
            visible={SettingModalVisible}
            onCancel={() => {
              dispatch(Actions.login.setSettingModalVisible(false));
            }}
          />

          <UserSettings
            handleAccountSettingsClick={() => {
              dispatch(Actions.login.setCurrentUserModalVisible(true));
            }}
            handleChangePasswordClick={() => {
              dispatch(Actions.login.setChangePasswordModalVisible(true));
            }}
            handleSettingsClick={() => {
              dispatch(Actions.login.setSettingModalVisible(true))
            }}
            logOut={() => {
              dispatch(authSlice.actions.logOut());
            }}
            userEmail={user.email}
          />
        </div>
      </div>
    </NavParent>
  );
};
